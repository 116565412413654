@import '_variables.scss';
@import '_breakpoints.scss';

html, body, div#root {
    height: 100%;
}

body {
	margin: 0;
	padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: $font-size-sm;
    color: #666666;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-base;
    }
}

a, a:hover {
    color: $brand-primary;
}

.btn-primary {
    background-color: $brand-primary;
    color: #fff;
    border-color: $brand-primary;
    text-transform: uppercase;
    border-radius: 2px;

    &:hover {
        background-color: #940800;
        color: #fff;
        border-color: #940800;
    }

    &.inverse {
        background-color: #fff;
        color: $brand-primary;
        border-color: #fff;

        &:hover {
            background-color: #eee;
            border-color: #eee;
        }
    }
}

.checkin {
    text-transform: capitalize;
    background-color: $brand-primary;

    a {
        color: #fff;
    }
}

.hero-copy {
    h2 {
        font-size: 2em;
        font-family: 'Seaweed Script', cursive;
        font-weight: 300;
        margin: 0;
        padding: 0;
        color: #000;
    }

    hr {
        width: 6%;
        border-color: $brand-primary;
        border-top-width: 3px;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 60px;
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        color: $brand-primary;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
    }

    h4 {
        font-size: 28px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 35px;
        color: #000000;
    }

    p {
        font-size: 18px;
    }

    &.inverse {
        padding: 15% 10% 0 20%;
        width: 70%;
    }

    &.inverse h2 {
        color: #fff;
    }

    &.inverse h3 {
        color: #fff;
        margin-bottom: 35px;
    }

    &.inverse hr {
        margin-left: 4%;
        width: 20%;
    }

    &.inverse p {
        color: #fff;
        width: 70%;
    }
}


.steps {
    background: transparent url('/img/hero-bg.jpg') top center repeat-y;
    background-size: 100%;
    min-height: 200px;
    position: relative;
    padding: 0 20%;
    text-align: center;
    padding-top: 58px;

    .icon svg {
        fill: #fff;
    }

    .col-12:nth-child(1) .icon svg {
        margin-top: 34px;
    }

    .col-12:nth-child(3) .icon svg,
    .col-12:nth-child(4) .icon svg {
        margin-top: 40px;
    }

    h2 {
        text-transform: uppercase;
        color: #fff;
        font-family: 'Oswald', sans-serif;
        font-size: 1.3rem;
        margin: 54px 0 24px 0;
    }

    p {
        color: #fff;
    }

    .cta .btn-primary {
        border-radius: 0;
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .cta {
        margin: 60px 0 100px 0;
    }

    &.features {
        padding: 0 10%;
    }
}

.accordion {
    margin-top: 128px;
}

@media only screen and (max-device-width : 544px) {
    .hero-copy h3 {
        font-size: 2em;
    }

    .our-product .hand {
        display: none;
    }

    .our-product .hero-copy {
        padding: 15% 10% 0 20%;
        width: 100%;
    }

    .our-product .hero-copy p {
        width: 100%;
    }

    .accordion {
        margin-top: 50px;
    }
}
