@import '../../_variables.scss';
@import '../../_breakpoints.scss';

.hero {
    background: transparent url('/img/hero-primary.jpg') top center no-repeat;
    height: 320px;
    background-size: auto 100%;

    @include media-breakpoint-up(md) {
        background-position: top center;
        background-size: 100% auto;
    }

    @include media-breakpoint-up(lg) {
        height: calc(100vh - 129px - 54px - 68px);
        background-size: auto;
    }

    &.secondary {
        background-position: center left;

        @include media-breakpoint-up(sm) {
            height: 436px;
            background: transparent url('/img/hero-secondary.jpg') top center no-repeat;
        }

        h1 {
            width: 100%;
            padding-top: 140px;

            @include media-breakpoint-up(sm) {
                padding: 180px 0 0 10%;
            }
        }
    }

    h1 {
        color: #fff;
        font-family: 'Oswald', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        width: 60px;
        display: block;
        line-height: 1em;
        font-size: 4rem;
        padding: 5% 0 0 5%;

        @include media-breakpoint-up(md) {
            padding: 5% 0 0 10%;
        }

        @include media-breakpoint-up(lg) {
            font-size: 6rem;
            padding: 5% 0 0 20%;
        }
    }
}
