@import '../../_variables.scss';
@import '../../_breakpoints.scss';

footer {
    background-color: #23272c;
    color: #c5c5c5;
    padding: 24px 0 10px 0;
    margin-top: 50px;
    font-size: $font-size-sm;

    @include media-breakpoint-up(lg) {
        margin-top: 150px;
        padding: 84px 0 20px 0;
    }

    .logo svg {
        fill: #34363a;
        max-width: 120px;

        @include media-breakpoint-up(sm) {
            max-width: none;
        }
    }

    strong {
        color: #fff;
    }

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    hr {
        border-color: #34363a;
        margin: 20px 0;

        @include media-breakpoint-up(sm) {
            margin: 70px 0 20px 0;
        }
    }
}
