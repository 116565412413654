@import '../_variables.scss';
@import '../_breakpoints.scss';

.product-login {
    background: none;
    margin: 0;
    height: auto;

    @include media-breakpoint-up(sm) {
        background: transparent url('/img/our-product-login.jpg') top right no-repeat;
        margin: 60px 0 0 0;
    }

    @include media-breakpoint-up(sm) {
        background-position: 130px top;
        height: 723px;
    }

    @include media-breakpoint-up(lg) {
        background-position: top right;
        height: 823px;
    }

    .col-12 {
        padding: 40px 0 20px 0;

        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }

        hr {
            margin-left: 2%;
            width: 18%;
        }
    }
}

.product-checkin {
    background-position: center right;
    height: auto;

    @include media-breakpoint-up(sm) {
        background: transparent url('/img/our-product-checkin.jpg') top center no-repeat;
        height: 770px;
    }

    @include media-breakpoint-down(sm) {
        background: transparent url('/img/our-product-checkin.jpg') top center no-repeat;
        background-size: auto 100%;
    }

    .points {
        @include media-breakpoint-down(sm) {
            span {
                float: left;
                width: 20%;
            }
            ul {
                float: left;
                width: 80%;
                margin-top: 0 !important;
            }
            .icon {
                margin-bottom: 10px;
            }
        }
    }
}

.product-experience {
    background-position: center right;
    height: auto;

    @include media-breakpoint-up(sm) {
        background: transparent url('/img/our-product-checkin.jpg') top center no-repeat;
        height: 770px;
    }
}

.product-obsessed {
    background-position: center right;
    height: auto;

    @include media-breakpoint-up(sm) {
        background: transparent url('/img/our-product-obsessed.jpg') top center no-repeat;
        height: 524px;
    }

    @include media-breakpoint-down(sm) {
        background: transparent url('/img/our-product-obsessed.jpg') top center no-repeat;
        height: 100% auto;
    }
}

.our-product-page {
    footer {
        margin: 0;
    }

    .hero-copy.inverse {
        padding: 40px 15px;

        @include media-breakpoint-up(sm) {
            padding: 10% 0 0 0;
        }

        p {
            text-align: center;
            width: 80%;
            margin: auto;
        }

        hr {
            margin-left: auto;
        }
    }

    .icon {
        color: #fff;
        text-align: center;

        svg path {
            fill: #fff;
        }

        svg polygon {
            fill: #fff;
        }

        ul {
            margin-top: 35px;

            li {
                text-align: left;
            }
        }
    }

    .our-product-page .slider .hero-copy h3 {
        font-size: 26px;
        margin: 0;

        @include media-breakpoint-up(sm) {
            margin: 70px 0 20px 0;
        }
    }

    .our-product-page footer {
        margin-top: 0;
    }
}

