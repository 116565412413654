@import '../../_variables.scss';
@import '../../_breakpoints.scss';

.header {
    .social ul {
        margin: 0 5px 0 0;

        @include media-breakpoint-up(sm) {
            margin: 0 45px 0 0;
        }
    }

    .social {
        background-color: #23272c;
        padding: 15px 0 10px 0;
    }

    .social svg path {
        fill: #c22c08;
    }

    .social .list-inline-item {
        margin: 0 0 0 22px;
    }

    .nav {
        height: 93px;
        padding: 0;

        @include media-breakpoint-up(sm) {
            height: auto;
            padding: 15px 45px 5px 55px;
        }
    }

    .nav svg.logo {
        max-width: 100px;

        @include media-breakpoint-up(sm) {
            max-width: 180px;
        }
    }

    .nav .menu ul {
        margin: 30px 0 0 0;
    }

    .nav .menu .list-inline-item {
        margin: 0 0 0 10px;

        @include media-breakpoint-up(sm) {
            margin: 0 0 0 40px;
        }
    }

    .nav .menu li a {
        text-transform: uppercase;
        font-weight: bold;
        font-family: 'Oswald', sans-serif;
        font-size: $font-size-sm;

        @include media-breakpoint-up(sm) {
            font-size: $font-size-base;
        }
    }
}
