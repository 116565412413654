.contact-us-page h2 {
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    color: #c22c08;
    text-transform: uppercase;
    text-align: center;
    margin: 120px 0 20px 0;
}

.contact-us-page form {
    padding: 0 20%;
}

.contact-us-page form label {
    color: #000;
    margin-bottom: 2px;
}

.contact-us-page p {
    margin-bottom: 25px;
}

.contact-us-page .required {
    color: #c22c08;
}

@media only screen and (max-device-width : 544px) {
    .contact-us-page h2 {
        font-size: 2em;
        margin-top: 40px;
    }

    .contact-us-page form {
        padding: 0;
    }
}
